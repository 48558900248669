import React from "react";
import logo from './img/2024Logos_150x132px.webp';
import Banner from "./Main_Banner";
import './header.css';
import './Media.css';

function Header() {
    return (
        <frameElement>
            <div className="header-container">
                <div className="header-col-left"><img src={logo} alt="Aqua Technics - Fibreglass Swimming Pools Perth" /></div>
                <div className="header-col-right desk"><h3>Our <em class="emph">promise </em>to you -<br />there simply is no <em class="emph">better </em>swimming <em class="emph">pool</em></h3></div>
                <div className="header-col-right mob"><h3>Welcome to <em class="emph">Aqua Technics</em></h3></div>
            </div>
            <div><Banner /></div>
        </frameElement>
    )
}

export default Header;



