import React from "react";
import './Home.css';

import awards from './img/award230.webp';
import Fibreglass from "./Fibreglass_Swimming_Pool";
import AusNetwork from "./AT_Australia_Network";
import Slider from "./Slider_steps";
import './Media.css';
function Home() {
    return (
        <frameElement>
            <Fibreglass />
            <div className="awardPanel">
                <div className="award-col-left"><h2>Australia’s most awarded pool company</h2></div>
                <div className="award-col-right"><img src={awards} className="award" alt="Australia’s most awarded pool company" /></div>
            </div>


            <AusNetwork />


            <Slider />

        </frameElement>
    )
}

export default Home;